<template>
  <v-text-field
    dense
    hide-details
    outlined
    single-line
    :append-icon="appendIcon"
    :label="label"
    :value="value"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: "SearchField",
  props: {
    appendIcon: { type: String, default: "mdi-magnify" },
    label: String,
    value: String,
  },
};
</script>

<style lang="sass" scoped>
.v-text-field--outlined:not(.v-input--is-focused)::v-deep
  fieldset
    border-color: rgba(0, 0, 0, 0.12)

.v-text-field--outlined.v-input--is-focused::v-deep
  .v-input__control > .v-input__slot > fieldset
    border: 2px solid #015ecc

.v-text-field--outlined::v-deep
  .v-input__control > .v-input__slot
    min-height: 48px !important

  .v-label
    top: 15px
    max-width: 100%

  .v-input__append-inner
    margin-top: 12px !important
</style>
