var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"show-select":"","disable-filtering":"","fixed-header":"","loading":_vm.loading,"headers":_vm.headers,"server-items-length":_vm.total,"items-per-page":_vm.perPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"page":_vm.page,"items":_vm.items,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] }},on:{"update:items-per-page":function($event){return _vm.$emit('update:per-page', $event)},"update:page":function($event){return _vm.$emit('update:page', $event)}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"green","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
var isMobile = ref.isMobile;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.description))])]),(!isMobile)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","target":"_blank","to":_vm.viewHref(item)},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-launch")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir em nova aba")])])],1):_vm._e()],1)]}}],null,true)})]}},{key:"item.sale_price",fn:function(ref){
var value = ref.value;
return [_c('currency',{attrs:{"hide-symbol":"","value":value}})]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('number',{attrs:{"value":item.stock}}),_c('span',{staticClass:"font-weight-medium ml-1",domProps:{"textContent":_vm._s(item.unit)}})]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }