<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div
      class="d-flex flex-column flex-sm-row flex-wrap mt-n3 pt-3 pb-sm-3 toolbar"
    >
      <v-btn
        v-show="selectedItems.length === 0"
        color="success"
        to="/cadastros/produtos/novo"
        class="mr-lg-auto mr-sm-4"
        :large="!$vuetify.breakpoint.xsOnly"
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
      >
        <v-icon :left="!$vuetify.breakpoint.xsOnly">mdi-plus-thick</v-icon>
        <span class="d-none d-sm-flex">Novo produto</span>
      </v-btn>

      <v-btn
        v-show="selectedItems.length > 0"
        large
        text
        outlined
        class="mb-4 mr-sm-4 mr-lg-auto"
        :loading="exporting"
        :block="$vuetify.breakpoint.xsOnly"
        @click="exportCSV"
      >
        <v-icon left color="primary">mdi-file-download-outline</v-icon>
        Exportar
      </v-btn>

      <div class="mb-4 flex-grow-1 flex-lg-grow-0">
        <search-field clearable label="Descrição" v-model="filterBySearch" />
      </div>
    </div>

    <v-expand-transition>
      <v-alert v-if="showSelectedAll" text color="secondary lighten-4">
        <v-row
          no-gutters
          class="flex-column justify-center flex-sm-row justify-sm-start align-center"
        >
          <v-col cols="auto mr-sm-1" class="secondary--text">
            <span v-if="!selectedAll">
              Todos os <strong>{{ selectedItems.length }}</strong> desta página
              estão selecionados
            </span>
            <span v-else>
              Todos os <strong>{{ total }}</strong> produtos estão selecionados
            </span>
          </v-col>

          <v-col cols="auto">
            <v-btn
              v-if="!selectedAll"
              text
              color="primary"
              @click="selectedAll = true"
            >
              Selecione todos os {{ total }} produtos
            </v-btn>

            <v-btn v-else text color="primary" @click="selectedItems = []">
              Limpar seleção
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-expand-transition>

    <v-row>
      <v-col cols="12">
        <product-table
          :loading="loading"
          :total="total"
          :per-page.sync="perPage"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="items"
          @click:row="rowClicked"
          @toggle-select-all="toggleSelectAll"
          v-model="selectedItems"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import $store from "@/store";
import DownloadFile from "@/mixins/DownloadFile";
import ProductTable from "@/components/Product/Table";
import SearchField from "@/components/SearchField";

export default {
  name: "RecordsProductList",
  metaInfo() {
    return {
      title: "Produtos",
    };
  },
  mixins: [DownloadFile],
  components: {
    SearchField,
    ProductTable,
  },
  data: () => ({
    exporting: false,
    requesting: false,
    selectedAll: false,
    selectedItems: [],
    showSelectedAll: false,
  }),
  computed: {
    sort() {
      if (!this.sortBy) return;
      const field = Array.isArray(this.sortBy) ? this.sortBy[0] : this.sortBy;
      const desc = Array.isArray(this.sortDesc)
        ? this.sortDesc[0]
        : this.sortDesc;
      return (desc ? "-" : "") + field;
    },
    ...mapState("auth", ["company"]),
    ...mapState({
      items: (state) =>
        state.products.list.items.map((p) => ({
          id: p.id,
          description: p.description,
          sale_price: p.sale_price,
          unit: p.unit,
          stock: p.stocks.find((s) => s.current_company)?.amount || 0,
        })),
      loading: (state) => state.products.loading,
      nextCursor: (state) => state.products.list.next_cursor,
      prevCursor: (state) => state.products.list.prev_cursor,
      total: (state) => state.products.list.total,
      params: (state) => state.products.params,
    }),
    filterBySearch: {
      set(v) {
        this.updateParams({ search: v || undefined, cursor: undefined });
      },
      get() {
        return this.params.search;
      },
    },
    perPage: {
      set(v) {
        this.updateParams({ perPage: v || undefined, cursor: undefined });
      },
      get() {
        return this.params.perPage;
      },
    },
    sortBy: {
      set(v) {
        this.updateParams({ sortBy: v || undefined });
      },
      get() {
        return this.params.sortBy;
      },
    },
    sortDesc: {
      set(v) {
        this.updateParams({ sortDesc: v || undefined });
      },
      get() {
        return this.params.sortDesc;
      },
    },
    page: {
      set(v) {
        const page = this.page || 1;
        if (v === page) return;
        this.updateParams({
          page: v,
          cursor: v > page ? this.nextCursor : this.prevCursor,
        });
      },
      get() {
        return this.params.page;
      },
    },
  },
  watch: {
    params() {
      this.loadProducts();
    },
    selectedItems() {
      this.showSelectedAll = this.selectedAll = false;
    },
    company() {
      this.$nextTick().then(() => {
        this.loadProducts();
        this.selectedItems = [];
      });
    },
  },
  methods: {
    updateParams(changes) {
      this.$store.commit("products/SET_PARAMS", {
        ...this.params,
        ...changes,
      });
    },
    loadProducts() {
      if (this.requesting) {
        clearTimeout(this.requesting);
      }

      this.requesting = setTimeout(() => {
        this.value = this.$store.dispatch("products/list", this.params);
      }, 300);
    },
    rowClicked(item) {
      this.$router.push({
        name: "product-view",
        params: { id: item.id },
      });
    },
    toggleSelectAll({ value }) {
      setTimeout(() => {
        if (value && this.selectedItems.length < this.total) {
          this.showSelectedAll = true;
        }
      }, 300);
    },
    async exportCSV() {
      this.exporting = true;

      var params = new URLSearchParams();
      if (!this.selectedAll) {
        this.selectedItems.forEach((item) => {
          params.append("product", item.id);
        });
      }

      const { headers, data } = await this.$http.get("/v1/products/export", {
        params,
      });

      const filename = headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0]
        .split(`"`)[1];
      const mimeType = headers["content-type"];

      this.downloadFile(filename, data, mimeType);
      this.exporting = false;
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (from.name !== "product-view") {
      await $store.dispatch("products/list", {
        perPage: 25,
      });
    }
    next();
  },
};
</script>
