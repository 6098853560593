<template>
  <v-data-table
    show-select
    disable-filtering
    fixed-header
    :loading="loading"
    :headers="headers"
    :server-items-length="total"
    :items-per-page="perPage"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :page="page"
    :items="items"
    :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
    @update:items-per-page="$emit('update:per-page', $event)"
    @update:page="$emit('update:page', $event)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item.data-table-select="{ isSelected, select }">
      <v-simple-checkbox
        color="green"
        :ripple="false"
        :value="isSelected"
        @input="select($event)"
      />
    </template>

    <template v-slot:item.description="{ item, isMobile }">
      <v-hover v-slot="{ hover }">
        <v-row no-gutters align="center" class="py-1">
          <v-col cols="auto">
            <span class="font-weight-medium">{{ item.description }}</span>
          </v-col>

          <v-col v-if="!isMobile" v-show="hover" class="pl-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  target="_blank"
                  :to="viewHref(item)"
                  @click.stop
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-launch</v-icon>
                </v-btn>
              </template>

              <span>Abrir em nova aba</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-hover>
    </template>

    <template v-slot:item.sale_price="{ value }">
      <currency hide-symbol :value="value" />
    </template>

    <template v-slot:item.stock="{ item }">
      <number :value="item.stock" />
      <span class="font-weight-medium ml-1" v-text="item.unit" />
    </template>
  </v-data-table>
</template>

<script>
import Currency from "@/components/Currency";
import Number from "@/components/Number";

export default {
  name: "ProductTable",
  components: {
    Number,
    Currency,
  },
  props: {
    loading: Boolean,
    total: Number,
    perPage: Number,
    page: Number,
    sortBy: [String, Array],
    sortDesc: [Boolean, Array],
    items: Array,
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome do produto",
          value: "description",
        },
        {
          text: "Preço de venda (R$)",
          value: "sale_price",
          align: "right",
          width: 160,
          sortable: false,
          filterable: false,
        },
        {
          text: "Estoque disponível",
          value: "stock",
          align: "right",
          width: 153,
          sortable: false,
          filterable: false,
        },
      ];
    },
  },
  methods: {
    viewHref(item) {
      return {
        name: "product-view",
        params: { id: item.id },
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.theme--light.v-data-table::v-deep
  .v-data-table__wrapper
    table
      tbody
        tr:hover
          cursor: pointer
</style>
